/** *************************************************************
* Copyright (C) 2016-2022 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import './style.scss';

import Content from './Content';
import InlineSVG from '../shared/InlineSVG';
import { PostHogProvider } from 'posthog-js/react';

const posthogOptions = {
  // eslint-disable-next-line camelcase
  api_host: 'https://us.i.posthog.com',
};

const App = ( ) => {
  return (
    <React.Fragment>
      <PostHogProvider
        apiKey="phc_x0tcXsjYDZrXF6gOzQW0u7khzvwMavTJnmM9FPKxIo1"
        options={ posthogOptions }
      >
        <nav>
          <div className="innerWrapper">
            <a href="https://deepsurface.com/" className="websiteLink" >
              <InlineSVG type="primaryLogoBug" version="bug" size="logoBug" />
              <InlineSVG
                type="primaryLogoText"
                version="dark"
                size="logoText"
                elementClass="logoText"
              />
              <span>Documentation</span>
            </a>
            <a className="websiteLink--pageLink" href="https://deepsurface.com/product/">Product</a>
            <a className="websiteLink--pageLink" href="https://deepsurface.com/integrations/">Integrations</a>
            <a className="websiteLink--pageLink" href="https://deepsurface.com/resources/">Resources</a>
            <a className="websiteLink--pageLink" href="https://deepsurface.com/about/">About</a>
            <a href="https://deepsurface.com/try-now/" className="tryNowLink">
              <span className="text">Try Now</span>
              <InlineSVG type="arrowRightAlt" />
            </a>
          </div>
        </nav>
        <Content />
      </PostHogProvider>
    </React.Fragment>
  );
};

export default App;