/** **************************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ****************************************************************************/
import React from 'react';

// SECTION ----------------- helpers and dictionaries ------------------------------- SECTION //
export const stringIsEmpty = testString => testString.trim() === '';
export const stringIsNotEmpty = testString => testString.trim() !== '';

export const arrayIsEmpty = testArray => testArray.length === 0;
export const arrayIsNotEmpty = testArray => testArray.length > 0;

export const objectIsEmpty = testObject => Object.keys( testObject ).length === 0 && testObject.constructor === Object;
// eslint-disable-next-line max-len
export const objectIsNotEmpty = testObject => Object.keys( testObject ).length !== 0 && testObject.constructor === Object;

export const mapIsEmpty = testMap => testMap.size === 0;
export const mapIsNotEmpty = testMap => testMap.size > 0;

// used everywhere. Accepts strings, arrays, objects, maps
export const isEmpty = test => {
  if ( test === null || test === 'undefined' ) {
    return true;
  }
  if ( test ) {
    if ( test.constructor === Array ) {
      return arrayIsEmpty( test );
    }
    if ( test.constructor === Object ) {
      return objectIsEmpty( test );
    }
    if ( typeof test === 'string' || test instanceof String ) {
      return stringIsEmpty( test );
    }
    if ( test instanceof( Map ) ) {
      return mapIsEmpty( test );
    }
    if ( Number.isInteger( test ) ) {
      return Number.isNaN( test );
    }
    return false;
  }
  return true;
};

// used everywhere. Accepts strings, arrays, objects, maps
export const isNotEmpty = test => {
  if ( test === null || test === 'undefined' ) {
    return false;
  }
  if ( ( typeof test === 'number' && test === 0 ) || test ) {
    if ( test.constructor === Array ) {
      return arrayIsNotEmpty( test );
    }
    if ( test.constructor === Object ) {
      return objectIsNotEmpty( test );
    }
    if ( typeof test === 'string' || test instanceof String ) {
      return stringIsNotEmpty( test );
    }
    if ( typeof test === 'number' ) {
      if ( test === 0 || test ) {
        return true;
      }
    }
    if ( test instanceof( Map ) ) {
      return mapIsNotEmpty( test );
    }
    return true;
  }
  if ( typeof test === 'number' ) {
    return true;
  }
  return false;
};

const getDataType = test => {
  if ( test === undefined ) {
    return undefined;
  }

  if ( itemIsObject( test ) ) {
    return 'object';
  } else if ( itemIsArray( test ) ) {
    return 'array';
  } else if ( itemIsString( test ) ) {
    return 'string';
  }  else if ( itemIsFloat( test ) ) {
    return 'float';
  } else if ( itemIsNumber( test ) ) {
    return 'number';
  } else if ( itemIsBoolean( test ) ) {
    return 'boolean';
  } else if ( test === null ) {
    return 'null';
  }
  return undefined;
};

export const itemIsObject = test => {
  return isNotEmpty( test ) && test.constructor === Object;
};

export const itemIsArray = test => {
  return isNotEmpty( test ) && Array.isArray( test );
};

export const itemIsString = test => {
  return typeof test === 'string' || test instanceof String;
};

export const itemIsNumber = test => {
  return typeof test === 'number';
};

export const itemIsFloat = test => {
  return typeof test === 'number' && !Number.isInteger( test );
};

export const itemIsBoolean = test => {
  return typeof test === 'boolean';
};

export const itemIsFunction = test => {
  return typeof test === 'function';
};

export const isEqual = ( a, b ) => {
  const aType = getDataType( a );
  const bType = getDataType( b );

  // they have the same type
  if ( aType === bType ) {
    if ( isEmpty( aType ) ) {
      return false;
    }

    // Object need to do some recursive lookups
    if ( aType === 'object' ) {
      const aKeys = Object.keys( a );
      const bKeys = Object.keys( b );

      if ( aKeys.length !== bKeys.length ) {
        return false;
      }

      const equalA = aKeys.every( aKey => isEqual( a[aKey], b[aKey] ) );
      const equalB = bKeys.every( bKey => isEqual( a[bKey], b[bKey] ) );

      return equalA === true && equalB === true;
    }

    // array need to do some recursive lookups
    if ( aType === 'array' ) {
      const aCopy = [ ...a ];
      const bCopy = [ ...b ];
      const sortedA = aCopy.sort();
      const sortedB = bCopy.sort();
      if ( sortedA.length === 0 && sortedB.length === 0 ) {
        return true;
      }
      if ( sortedA.length !== sortedB.length ) {
        return false;
      }
      const equal = sortedA.every( ( item, i ) => isEqual( item, sortedB[i] ) );
      return equal;

    }

    // string/number/float
    if ( aType === 'string' || aType === 'number' || aType === 'float' ) {
      return a === b;
    }

    if ( aType === 'boolean' ) {
      return a === b;
    }

    if ( aType === 'null' ) {
      return a === b;
    }
    return false;
  }
  return false;

};

export const isFalsey = test => {

  if ( test === null || test === undefined ) {
    return true;
  }

  if ( itemIsBoolean( test ) ) {
    return test === false;
  }

  if ( itemIsNumber( test ) ) {
    return test === 0;
  }

  if ( itemIsString( test ) ) {
    return test === '0' || test === 'false' || test === 'null';
  }

  return false;
};

export const isTruthy = test => {
  if ( isEmpty( test ) ) {
    return false;
  }
  if ( itemIsBoolean( test ) ) {
    return test === true;
  }

  if ( itemIsNumber( test ) ) {
    return test <= 0;
  }

  if ( itemIsString( test ) ) {
    return test === 'true' || test === '1';
  }
  return false;
};

// if we ever need to isolate functionality only for Safari
export const isSafari = navigator.vendor && navigator.vendor.indexOf( 'Apple' ) > -1 &&
                        navigator.userAgent &&
                        navigator.userAgent.indexOf( 'CriOS' ) === -1 &&
                        navigator.userAgent.indexOf( 'FxiOS' ) === -1;

// if we ever need to isolate functionality only for FF
export const isFirefox =  navigator.userAgent.toLowerCase().indexOf( 'firefox' ) > -1;

// mirrors globals.scss
export const globalColors = {

  // primary colors
  'primaryBlue': '#00AAE9',
  'darkBlue': '#334D6E',
  'darkBlue--100': '#334D6E',
  'darkBlue--80': '#5c718b',
  'darkBlue--60': '#8594a8',
  'darkBlue--40': '#adb8c5',
  'darkBlue--20': '#d6dbe2',

  'filter--teal': '#1ABC9C',
  'filter--blue': '#3498DB',
  'filter--purple': '#9B59B6',
  'filter--red': '#E74C3C',

  'default': '#1ab3ec',

  // greys
  'grey': '#90A0B7',
  'grey--icon': '#C2CFE0',
  'grey--divider': '#EBEFF2',
  'grey--background': '#F7F7FA',
  'grey--background--light': '#FCFCFD',

  // risk colors and variants
  'critical': '#c33937',
  'high': '#ea5e50',
  'moderate': '#ff961a',
  'low': '#ffdf0d',
  'minimal': '#43d988',
  'primary': '#1ab3ec',
  'unknown': '#90A0B7',

  'critical--75': '#cd5958',
  'high--75': '#ed796d',
  'moderate--75': '#ffa740',
  'low--75': '#ffe640',
  'minimal--75': '#62df9b',
  'primary--75': '#40bfef',

  'critical--50': '#de9190',
  'high--50': '#f3a69e',
  'moderate--50': '#ffc580',
  'low--50': '#ffee80',
  'minimal--50': '#97eabd',
  'primary--50': '#80d5f4',
  'unknown--50': '#C2CFE0',

  'critical--30': '#ebbdbd',
  'high--30': '#f8cac5',
  'moderate--30': '#ffdcb3',
  'low--30': '#fff5b3',
  'minimal--30': '#c1f3d8',
  'primary--30': '#b3e6f9',
  'unknown--30': '#EBEFF2',

  'critical--15': '#f5dede',
  'high--15': '#fce4e2',
  'moderate--15': '#ffeed9',
  'low--15': '#fffad9',
  'minimal--15': '#e0f9eb',
  'primary--15': '#d9f2fc',

  'critical--10': '#f9e9e9',
  'high--10': '#fdeeec',
  'moderate--10': '#fff4e6',
  'low--10': '#fffce6',
  'minimal--10': '#ebfbf2',
  'primary--10': '#e6f7fd',

  // status colors and variants
  'status--blue': '#2D9CDB',
  'status--green': '#6FCF97',
  'status--yellow': '#F9CD49',
  'status--orange': '#F2994A',
  'status--red': '#F7685B',

  'status--green--75': '#93dbb1',
  'status--green--50': '#b7e7cb',
  'status--green--25': '#dbf3e5',
  'status--green--10': '#f1fbf5',

  'status--red--10': '#fff0ef',
  'status--red--50': '#faa49d',
  'status--blue--75': '#60b3e3',
  // 'status--green--75': '#60b3e3',
  'status--yellow--75': '#fbda77',
  'status--orange--75': '#f3b176',
  'status--red--75': '#f78c83',

  'critical--5': '#fdf6f6',
  'high--5': '#fef8f7',
  'moderate--5': '#fffbf5',
  'low--5': '#fffef5',
  'minimal--5': '#f7fdfa',

  'status--blue--100': '#2D9CDB',
  'status--blue--80': '#57b0e2',
  'status--blue--60': '#81c4e9',
  'status--blue--40': '#abd7f1',
  'status--blue--20': '#d5ebf8',

  'status--green--100': '#6FCF97',
  'status--green--80': '#8cd9ac',
  'status--green--60': '#a9e2c1',
  'status--green--40': '#c5ecd5',
  'status--green--20': '#e2f5ea',

  'status--yellow--100': '#F9CD49',
  'status--yellow--80': '#fad76d',
  'status--yellow--60': '#fbe192',
  'status--yellow--40': '#fdebb6',
  'status--yellow--20': '#fef5db',

  'status--orange--100': '#F2994A',
  'status--orange--80': '#f5ad6e',
  'status--orange--60': '#f7c292',
  'status--orange--40': '#fad6b7',
  'status--orange--20': '#fcebdb',

  'status--red--100': '#F7685B',
  'status--red--80': '#f9867c',
  'status--red--60': '#faa49d',
  'status--red--40': '#fcc3bd',
  'status--red--20': '#fde1de',
};

export const tagColors = {
  one: [
    '#343434',
    '#f94144',
    '#f3722c',
    '#f8961e',
    '#f9844a',
    '#f9c74f',
    '#90be6d',
    '#43aa8b',
    '#4d908e',
    '#577590',
    '#277da1',
  ],
  two: [
    '#5f5f5f',
    '#fb8d8f',
    '#f8aa80',
    '#fbc078',
    '#fbb592',
    '#fbdd95',
    '#bcd8a7',
    '#8eccb9',
    '#94bcbb',
    '#9aacbc',
    '#7db1c7',
  ],
  three: [
    '#8a8a8a',
    '#ffdd00',
    '#ffbe0b',
    '#fb5607',
    '#f94144',
    '#ff006e',
    '#8338ec',
    '#560bad',
    '#3f37c9',
    '#3a86ff',
    '#4cc9f0',
  ],
  four: [
    '#cbcbcb',
    '#ffeb66',
    '#ffd86d',
    '#fd9a6a',
    '#fb8d8f',
    '#ff66a8',
    '#b588f4',
    '#9a6dce',
    '#8c87df',
    '#89b6ff',
    '#94dff6',
  ],
};

export const hexToRGB = hex => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec( hex );
  return result
    ? {
      r: parseInt( result[1], 16 ),
      g: parseInt( result[2], 16 ),
      b: parseInt( result[3], 16 ),
    }
    : null;
};

export const [ defaultTagColor ] = tagColors.four;

// SECTION ----------------- Promise Helpers -------------------------- SECTION //
// takes an array of promises and returns an array of resolved promises in the same order, handled
// sequentially when Promise.all would put too much of a burst on the server
export const promiseAllSequential = async ( promises, options={} ) => {

  if ( isNotEmpty( options ) && isNotEmpty( options.chunkSize ) ) {
    const chunks = [];

    for ( let i = 0; i < promises.length; i += options.chunkSize ) {
      const chunk = promises.slice( i, i + options.chunkSize );
      chunks.push( chunk );
    }
    let results = [];

    for ( let index = 0; index < chunks.length; index++ ) {

      const chunk = chunks[index];

      const slicedResults = await Promise.all( chunk.map( fn => fn() ) );

      results = [
        ...results,
        ...slicedResults,
      ];
    }
    return results;
  }

  const resolvedPromises = [];
  return promises.reduce( ( p, item, index ) => {
    return p.then( () => {
      return promises[index]( item ).then( response => {
        resolvedPromises.push( response );
        return resolvedPromises;
      } );
    } );
  }, Promise.resolve() );

};

// SECTION ----------------- params / hash / navigation ------------------------------- SECTION //
export const replaceURLHash = url => {
  window.location.href = url;
};

export const decodeURLHash = url => {

  const paramDelimiter = '&';
  const kvDelimiter = '=';

  if ( isEmpty( url ) ) {
    url = document.location.href;
  }

  let hash = '';

  if ( url.search( '#' ) > -1 ) {
    // eslint-disable-next-line
    hash = url.split( '#', 2 )[1];
  }

  const params = hash.split( paramDelimiter );

  var decodedHash = {};

  params.map( param => {
    const kv = param.split( kvDelimiter, 2 );

    const attrKey = decodeURIComponent( kv[0] );
    const attrVal = decodeURIComponent( kv[1] );

    decodedHash[attrKey] = attrVal;
  } );

  return decodedHash;
};

export const serializeURLHash = params => {

  const paramDelimiter = '&';
  const kvDelimiter = '=';

  const paramsList = [];

  for ( var key in params ) {
    if ( isNotEmpty( key ) ) {

      let _val = params[key];
      if ( Array.isArray( _val ) ) {
        _val = JSON.stringify( _val );
      }

      // encode obj. values as search params if value is an obj. otherwise encode normally
      const encodedValue = itemIsObject( _val )
        ? encodeURIComponent( JSON.stringify( _val ) )
        : encodeURIComponent( _val );

      paramsList.push( `${encodeURIComponent( key )}${kvDelimiter}${encodedValue}` );
    }
  }
  return paramsList.join( paramDelimiter );
};

export const removeFromURLHash = key => {
  const oldParams = decodeURLHash();

  if ( isNotEmpty( oldParams[key] ) ) {
    delete oldParams[key];
    const serializedHash = serializeURLHash( oldParams );
    window.history.pushState( decodeURLHash(), null, `#${serializedHash}` );
  }
};

export const encodeURLHash = ( updates, replace=false ) => {
  const oldParams = decodeURLHash();

  const newParams = { ...oldParams, ...updates };

  Object.entries( newParams ).map( ( [ key, value ] ) => {
    if ( isEmpty( value ) && value !== 0 ) {
      delete newParams[key];
    }
  } );

  const newHash = `#${serializeURLHash( newParams )}`;

  if ( !isEqual( oldParams, newParams ) ) {
    if ( replace ) {
      window.history.replaceState( newParams, null, newHash );
    } else {
      window.history.pushState( newParams, null, newHash );
    }
  }
};

export const triggerHashRefresh = () => window.dispatchEvent( new HashChangeEvent( 'hashchange' ) );


// SECTION ----------------- localStorage helpers ------------------------------- SECTION //
export const useLocalStorage = ( key, initialValue ) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [ storedValue, setStoredValue ] = React.useState( () => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem( key );
      // Parse stored json or if none return initialValue
      return isNotEmpty( item ) ? JSON.parse( item ) : initialValue;
    } catch ( error ) {
      // If error also return initialValue
      return initialValue;
    }
  } );

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value( storedValue ) : value;
      // Save state
      setStoredValue( valueToStore );
      // Save to local storage
      window.localStorage.setItem( key, JSON.stringify( valueToStore ) );
    } catch ( error ) {
      // A more advanced implementation would handle the error case
      console.log( error );
    }
  };

  return [ storedValue, setValue ];
};

export const addToLocalStorage = ( newItem, storageKey, setter, limit=10 ) => {

  let _toAdd = {};

  if ( isNotEmpty( window.localStorage.getItem( storageKey ) ) ) {
    const existingItems = JSON.parse( window.localStorage.getItem( storageKey ) );

    // if there are already 20 items, remove the first item before adding the new one
    if ( Object.keys( existingItems ).length >= limit ) {
      delete existingItems[Object.keys( existingItems )[0]];
    }

    // if the item is already in the history, do nothing
    if ( isEmpty( existingItems[newItem.id] ) ) {
      _toAdd = { ...existingItems, [newItem.id]: { ...newItem } };
    } else {
      _toAdd = { ...existingItems };
    }

  } else {
    _toAdd = { [newItem.id]: { ...newItem } };
  }

  setter( _toAdd );
};

export const removeFromLocalStorage = ( itemKey, storageKey, setter ) => {
  if ( isNotEmpty( window.localStorage.getItem( storageKey ) ) ) {
    const existingItems = JSON.parse( window.localStorage.getItem( storageKey ) );

    // remove the item from the exsting ones
    if ( existingItems[itemKey] ) {
      delete existingItems[itemKey];
    }

    if ( isNotEmpty( existingItems ) ) {
      // and then reset the object
      setter( existingItems );
      window.localStorage.setItem( storageKey, JSON.stringify( existingItems ) );
    } else {
      // and then reset the object
      setter( {} );
      window.localStorage.setItem( storageKey, '' );
    }

  } else {
    setter( {} );
    window.localStorage.setItem( storageKey, '' );
  }
};

export const clearLocalStorage = ( storageKey, setter ) => {
  if ( window.confirm( 'Are you sure you want to clear all of your saved items?' ) ) {
    setter( {} );
    window.localStorage.setItem( storageKey, '' );
  }
};

export function sleep( ms ) {
  return new Promise( resolve => setTimeout( resolve, ms ) );
}

export const copyToClipBoard = text => {
  navigator.clipboard.writeText( text );
};

export const getDimensionsAndOffset = ( el, container=null ) => {

  if ( el ) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + ( isNotEmpty( container ) ? container.scrollTop : window.scrollY ),
      width: rect.width,
      height: rect.height,
    };
  }
  return {
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  };

};

// help hack to see if it is loaded or not
export const helpIsLoaded = filePathMap => isNotEmpty( filePathMap )
  && isNotEmpty( filePathMap.user_guide )
  && isNotEmpty( filePathMap.user_guide.setup )
  && isNotEmpty( filePathMap.user_guide.setup.users )
  && isNotEmpty( filePathMap.user_guide.setup.users.items );

// try to only do sorting on the server side, only use if that is not possible
export const recordSorter = ( field, reverse, a, b ) => {
  if ( reverse ) {
    if ( a[field] > b[field] ) {
      return 1;
    }

    if ( a[field] < b[field] ) {
      return -1;
    }

  } else {
    if ( a[field] < b[field] ) {
      return 1;
    }

    if ( a[field] > b[field] ) {
      return -1;
    }

    // Makes sorting order stable even if fields are equal
    if( a.id > b.id ) {
      return -1;
    }

    if( a.id < b.id ) {
      return 1;
    }
  }

  return 0;
};

// helpful function for deduping an array of objects
export const uniqueArray = nonUniqueInput => {

  if ( !Array.isArray( nonUniqueInput ) ) {
    return nonUniqueInput;
  }

  let dedupedArray = [];

  let deduped = new Set();

  if ( isNotEmpty( nonUniqueInput ) ) {
    nonUniqueInput.map( i => {
      if ( isNotEmpty( i ) ) {
        deduped.add( JSON.stringify( i ) );
      }
    } );

    deduped = Array.from( deduped );
    dedupedArray = deduped.map( JSON.parse );
  } else {
    dedupedArray = [];
  }

  return dedupedArray;
};


// takes a underscored string and breaks it into captialized words
export const capitalize = string => {
  if ( isNotEmpty( string ) ) {
    const words = string.split( '_' );
    const capitalizedParts = [];
    for ( var part of words ) {
      capitalizedParts.push( part.charAt( 0 ).toUpperCase() + part.slice( 1 ) );
    }
    return capitalizedParts.join( ' ' );
  }

  return '';
};

// utility function for handling pluralization of records
export const pluralizeType = ( recordType, shouldCapitalize=false ) => {
  const pluralizationMap = {
    host:           'hosts',
    scope:          'scopes',
    patch:          'patches',
    vulnerability:  'vulnerabilities',
    node:           'nodes',
    edge:           'edges',
    segment:        'segment',
    escalation:     'escalations',
    path:           'paths',
    instance:       'Vulnerability Instances',
    scans:          'Vulnerability Instances',
    // eslint-disable-next-line camelcase
    sensitive_asset_policy: 'Sensitive Asset Policies',
  };

  const plural = pluralizationMap[recordType] || recordType;

  return shouldCapitalize ? capitalize( plural ) : plural;
};
