/* eslint-disable max-len, camelcase */

// HOW TO MAINTAIN HELP CONTENT
//
// This file contains a lot of the functions and objects that control how the documentation for both the
// risk analyzer application and public website function (since they both use the same code)
//
// 1. HIERARCHY
// The help content ALWAYS follows this hierarchy: main > section > page, for all areas except for the
// product information main, that area needs to compile and combine dynamically built.
//
// MAIN: The top level entry in the help menu and corresponds with the directory names in
// ./documentation/src/markdown/[MAIN_KEY]
// current main areas:
// - Installation Guide
// - User Guide
// - Integrations Guide
// - Security Guide
// - Product Information
//
// SECTION: The next level sections within a main. For example, 'Risk Insight' would correspond with:
// ./documentation/src/markdown/user_guide/risk_insight
//
// PAGE: an actual page of content, there are 3 types of pages:
//   normal page: the vast majority of the pages of content within help that guide the user on a specific thing
//
//   _section_index: these special index pages exist as the first page within a given section and follow the naming
//   convention: `[SECTION_KEY]_section_index.md`. They must follow this convention so that they do not get rendered
//   within the help menu as a normal page
//
//   _main_index: these pages function similary to the section index pages but for the main instead of a section, they
//   must follow the namping convention `[MAIN_KEY]_main_index.md` so that they do not get included in the menu
//
// 2. ADDING A NEW PAGE TO AN EXISTING SECTION
//
// Step 1: Add the new md file with a unique name in the section directory that you would like the page to appear
// within. For example, adding a new page to the risk insight help section would mean adding something like:
// new_report.md => ./documentation/src/markdown/user_guide/risk_insight
//
// Step 2: Add a new page entry in the order you would like it to apear in the help menu into the pageOrderAndLabels
// array below. That array is the single source of truth for the order the pages exist in the documentation
// and is a single flat array with comments to help keep track of where we are in the hierarchy. When adding
// a new page, make sure the pageKey is unique and that it follows all the conventions of other page entries, ie:
// { mainKey: 'existing_main_key', sectionKey: 'existing_section_key', pageKey: 'new_unique_page_key', label: 'New Page Label' }
//
// 3. EDITING AN EXISTING PAGE
//
// simply find the md file of the page you want to edit and change what is needed, use other pages as examples of what
// elements should and should not be used (ie, don't use H1s all over the place)
//
// 4. CREATING A NEW SECTION
//
// Adding a new section to an existing main is similar to adding a new page to an existing section.
//
// Step 1: Create a new directory of the name for the section within the desired main in ./documentation/src/markdown/[MAIN_KEY].
//
// Step 2: within the new section directory create at least 2 md files: a `[SECTION]_section_index.md` and at least one
// page of content for this section
//
// Step 3: Add a new section into the sectionOrderAndLabels array in the order you would like it to appear in the
// help navigation menu. For example, adding a new section called 'gotchas' in the installation guide:
// { mainKey: 'installation_guide', sectionKey: 'gotchas', label: 'Gotchas (things to be aware of)' }
//
// Step 4: Add the new page entries for the (at least) 2 new markdown files into the pageOrderAndLabels array where you
// would like them to appear in the order of the help menu. Since this is a brand-new section, add a new comment so that
// it is easier to keep track of these pages in the future. Follow all conventions for adding a new page as described
// above. Following the example of adding a new gotchas section, the following would be added to the pageOrderAndLabels
// array:
// // installation_guide / gotchas
// { mainKey: 'installation_guide', sectionKey: 'gotchas', pageKey: 'gotchas_section_index', label: 'Gotchas (things to be aware of)' },
// { mainKey: 'installation_guide', sectionKey: 'gotchas', pageKey: 'new_gotcha_file', label: 'An Important Gotcha' },
//
// 5. CREATING A NEW MAIN
//
// adding a new main is almost the exact same process as adding a new section, just one directory higher.
//
// Step 1: Create a new directory of the name for the main within ./documentation/src/markdown/.
//
// Step 2: Add a new md file within the newly created main directory called [MAIN_KEY]_main_index.md
//
// Step 3: Add a new entry into the mainOrderAndLabels array in the order you want it to appear in the help menu
// be sure to follow all conventions of the other entries, ie:
// { mainKey: 'new_main_key': 'New Main Label' }
//
// Step 4: Create a new directory of the name for the section within the desired main in ./documentation/src/markdown/[MAIN_KEY].
//
// Step 5: within the new section directory create at least 2 md files: a `[SECTION]_section_index.md` and at least one
// page of content for this section
//
// Step 6: Add a new section into the sectionOrderAndLabel array in the order you would like it to appear in the
// help navigation menu. For example, adding a new section called 'gotchas' in the installation guide:
// { mainKey: 'installation_guide', sectionKey: 'gotchas', label: 'Gotchas (things to be aware of)' }
//
// Step 7: Add the new page entries for the (at least) 3 new markdown files into the pageOrderAndLabels array where you
// would like them to appear in the order of the help menu. Since this is a brand-new section, add a new comment so that
// it is easier to keep track of these pages in the future. Follow all conventions for adding a new page as described
// above. Following the example of adding a new gotchas section, the following would be added to the pageOrderAndLabels
// array:
//
// { mainKey: 'new_main', sectionKey: null, pageKey: 'new_main_main_index.md', label: 'New Main Label' }
// // new_main / new_section
// { mainKey: 'new_main', sectionKey: 'new_section', pageKey: 'new_section_section_index', label: 'New Section' },
// { mainKey: 'new_main', sectionKey: 'new_section', pageKey: 'new_section_file', label: 'New Section File' },
//
// 6. RE-ORDERING MAINS
//
// Step 1: reorder the entries in the mainOrderAndLabels array to reflect what you would like the new order to be.
//
// Step 2: reorder the sections for that main in the sectionOrderAndLabels array to now be in the new main order.
//
// Step 3: reorder the pages in pageOrderAndLabels to reflect the new global ordering.
//
// 7. RE-ORDERING SECTIONS
//
// follow the same steps as above when re-ordering the mains, but omit step 1.

import { capitalize, isEmpty, isNotEmpty } from '../shared/Utilities';

// using webpack context api to load all the file paths of all the md files
// eslint-disable-next-line no-undef
const mdContext = require.context( './../../../../../documentation/src/markdown', true, /\.md$/ );

// all the relative file paths,
const mdKeys = mdContext.keys();

// all of the actual modules
const mdModules = mdKeys.map( filename => mdContext( filename ) );

// will be filled with relative paths => corresponding module in a moment
const keyedModules = {};

// filling out the keyedModules and hierarchy
mdKeys.map( ( key, index ) => {
  keyedModules[key] = mdModules[index];
} );

// IMPORTANT ------------------------------------------------------------- //
// WHEN ADDING/RE-ORDERING NEW MAINS, SECTIONS, AND PAGES, READ THE HELP ABOVE AND THEN EDIT THE CORRESPONDING ARRAYS BELOW

// pageKeys for product information pages, need to render differently than all of the other content,
export const productInformationPages = [
  'changelogs',
  'eula',
  'licenses',
];

// when adding a new main to the hierarchy of the help, add a new entry here in the order
// you would like it to appear in the help menu, follow all conventions as other entries, ie:
// { mainKey: 'new_main_key', label: 'New Main Label' }
export const mainOrderAndLabels = [
  { mainKey: 'installation_guide', label: 'Installation Guide' },
  { mainKey: 'user_guide', label: 'User Guide' },
  { mainKey: 'integrations_guide', label: 'Integrations Guide' },
  { mainKey: 'security_guide', label: 'Security Guide' },
  { mainKey: 'product_information', label: 'Product Information' },
];

// when adding a new section to the hierarchy of the help, add a new entry here in the order
// you would like it to appear in the help menu. There are comments within this array to keep track
// of the different main area that we are in.
// follow all conventions as other entries, ie:
// { mainKey: 'existing_main_key', sectionKey: 'new_section_key', label: 'New Section Label' }
export const sectionOrderAndLabels = [

  // installation_guide
  { mainKey: 'installation_guide', sectionKey: 'getting_started', label: 'Getting Started' },
  { mainKey: 'installation_guide', sectionKey: 'deployment_options', label: 'Deployment Planning' },
  { mainKey: 'installation_guide', sectionKey: 'deployment_tools', label: 'Deployment Tools' },
  { mainKey: 'installation_guide', sectionKey: 'virtual_machines', label: 'Virtual Machines' },
  { mainKey: 'installation_guide', sectionKey: 'miscellaneous', label: 'Additional Items to Consider' },

  // user_guide
  { mainKey: 'user_guide', sectionKey: 'reporting', label: 'Reporting' },
  { mainKey: 'user_guide', sectionKey: 'risk_insight', label: 'Risk Insight' },
  { mainKey: 'user_guide', sectionKey: 'remediation', label: 'Remediation' },
  { mainKey: 'user_guide', sectionKey: 'explore', label: 'Explore' },
  { mainKey: 'user_guide', sectionKey: 'activity', label: 'Activity' },
  { mainKey: 'user_guide', sectionKey: 'scanning', label: 'Scanning' },
  { mainKey: 'user_guide', sectionKey: 'setup', label: 'Setup' },

  // integrations_guide
  { mainKey: 'integrations_guide', sectionKey: 'vulnerability_scanners', label: 'Vulnerability Sources' },
  { mainKey: 'integrations_guide', sectionKey: 'remediation_integrations', label: 'Remediation' },
  { mainKey: 'integrations_guide', sectionKey: 'providers', label: 'Authentication Providers' },
  { mainKey: 'integrations_guide', sectionKey: 'pam', label: 'PAM' },

  // security_guide
  { mainKey: 'security_guide', sectionKey: 'firewall_config', label: 'Firewall Configuration' },
  { mainKey: 'security_guide', sectionKey: 'how_scans_work', label: 'How DeepSurface Scans Work' },
  { mainKey: 'security_guide', sectionKey: 'other_security_items', label: 'Other Items' },
];

// when adding a new page to the hierarchy of the help, add a new entry here in the order
// you would like it to appear in the help menu. There are comments within this array to keep track
// of the different main area and section that we are in. New pageKey MUST BE UNIQUE.
// follow all conventions as other entries, ie:
// { mainKey: 'existing_main_key', sectionKey: 'existing_section_key', pageKey: 'new_unique_page_key', label: 'New Page Label' }
export const pageOrderAndLabels = [

  { mainKey: null, sectionKey: null, pageKey: 'documentation_index', label: 'Documentation' },


  { mainKey: 'installation_guide', sectionKey: null, pageKey: 'installation_guide_main_index', label: 'Installation Guide' },

  // installation_guide / getting_started,
  { mainKey: 'installation_guide', sectionKey: 'getting_started', pageKey: 'overview', label: 'Overview' },
  { mainKey: 'installation_guide', sectionKey: 'getting_started', pageKey: 'saas', label: 'Let DeepSurface Host For You' },
  { mainKey: 'installation_guide', sectionKey: 'getting_started', pageKey: 'getting_started_section_index', label: 'Getting Started' },
  { mainKey: 'installation_guide', sectionKey: 'getting_started', pageKey: 'requirements', label: 'System Requirements' },
  { mainKey: 'installation_guide', sectionKey: 'getting_started', pageKey: 'quick_start', label: 'Self Hosted Quick Start - Installing to Cloud Platforms' },
  { mainKey: 'installation_guide', sectionKey: 'getting_started', pageKey: 'ova_first_login', label: 'Self Hosted - Installation Using an OVA' },
  { mainKey: 'installation_guide', sectionKey: 'getting_started', pageKey: 'package_installation', label: 'Registration, Package Installation, and Initialization' },
  { mainKey: 'installation_guide', sectionKey: 'getting_started', pageKey: 'first_steps', label: 'First Steps After Initialization of the Console' },

  // installation_guide / deployment_options,
  { mainKey: 'installation_guide', sectionKey: 'deployment_options', pageKey: 'deployment_options_section_index', label: 'Deployment Options' },
  { mainKey: 'installation_guide', sectionKey: 'deployment_options', pageKey: 'console_config', label: 'Main and Subordinate Consoles' },
  { mainKey: 'installation_guide', sectionKey: 'deployment_options', pageKey: 'agent', label: 'Agent-Based Deployment' },
  { mainKey: 'installation_guide', sectionKey: 'deployment_options', pageKey: 'user_managed_reference', label: 'User Managed Scan Deployment' },
  { mainKey: 'installation_guide', sectionKey: 'deployment_options', pageKey: 'agentless', label: 'Credentialed Scanning Deployment' },
  { mainKey: 'installation_guide', sectionKey: 'deployment_options', pageKey: 'mixed', label: 'Mixed Environment' },

  // installation_guide / deployment_tools
  { mainKey: 'installation_guide', sectionKey: 'deployment_tools', pageKey: 'deployment_tools_section_index', label: 'Deployment Tools' },
  { mainKey: 'installation_guide', sectionKey: 'deployment_tools', pageKey: 'win_gpo', label: 'Active Directory Group Policy' },
  { mainKey: 'installation_guide', sectionKey: 'deployment_tools', pageKey: 'mecm', label: 'Microsoft Endpoint Configuration Manager (part of InTune)' },
  { mainKey: 'installation_guide', sectionKey: 'deployment_tools', pageKey: 'tanium_deploy', label: 'Tanium Deploy' },
  { mainKey: 'installation_guide', sectionKey: 'deployment_tools', pageKey: 'bigfix', label: 'HCL BigFix' },
  { mainKey: 'installation_guide', sectionKey: 'deployment_tools', pageKey: 'ivanti', label: 'Ivanti' },

  // installation_guide / virtual_machines,
  { mainKey: 'installation_guide', sectionKey: 'virtual_machines', pageKey: 'virtual_machines_section_index', label: 'Virtual Machines' },
  { mainKey: 'installation_guide', sectionKey: 'virtual_machines', pageKey: 'vm_ware', label: 'VMWare' },
  { mainKey: 'installation_guide', sectionKey: 'virtual_machines', pageKey: 'virtual_box', label: 'Virtual Box' },
  { mainKey: 'installation_guide', sectionKey: 'virtual_machines', pageKey: 'vbox_guest_additions', label: 'VirtualBox Guest Additions' },
  { mainKey: 'installation_guide', sectionKey: 'virtual_machines', pageKey: 'aws_ec2_byol', label: 'AWS EC2 (BYOL)' },
  { mainKey: 'installation_guide', sectionKey: 'virtual_machines', pageKey: 'aws_ec2_usage_based', label: 'AWS EC2 (Usage Based)' },
  { mainKey: 'installation_guide', sectionKey: 'virtual_machines', pageKey: 'azure', label: 'Azure Cloud' },
  { mainKey: 'installation_guide', sectionKey: 'virtual_machines', pageKey: 'google', label: 'Google Cloud' },
  // installation_guide / miscellaneous,
  { mainKey: 'installation_guide', sectionKey: 'miscellaneous', pageKey: 'miscellaneous_section_index', label: 'Additional Items to Consider' },
  { mainKey: 'installation_guide', sectionKey: 'miscellaneous', pageKey: 'certificates_reference', label: 'Main Console Server Certificates' },
  { mainKey: 'installation_guide', sectionKey: 'miscellaneous', pageKey: 'ldap', label: 'LDAP' },
  { mainKey: 'installation_guide', sectionKey: 'miscellaneous', pageKey: 'tofu', label: 'TOFU' },
  { mainKey: 'installation_guide', sectionKey: 'miscellaneous', pageKey: 'clock_sync', label: 'Clock Sync' },
  { mainKey: 'installation_guide', sectionKey: 'miscellaneous', pageKey: 'ds_commands', label: 'DeepSurface Commands' },
  { mainKey: 'installation_guide', sectionKey: 'miscellaneous', pageKey: 'mult_vuln_sources', label: 'Multiple Vulnerability Sources' },
  { mainKey: 'installation_guide', sectionKey: 'miscellaneous', pageKey: 'documentation', label: 'API Documentation' },

  { mainKey: 'user_guide', sectionKey: null, pageKey: 'user_guide_main_index', label: 'User Guide' },
  // user_guide / reporting
  { mainKey: 'user_guide', sectionKey: 'reporting', pageKey: 'reporting_section_index', label: 'Reporting' },
  { mainKey: 'user_guide', sectionKey: 'reporting', pageKey: 'reporting_dashboard', label: 'Dashboards' },
  { mainKey: 'user_guide', sectionKey: 'reporting', pageKey: 'exports', label: 'Exports' },
  // user_guide / risk_insight
  { mainKey: 'user_guide', sectionKey: 'risk_insight', pageKey: 'risk_insight_section_index', label: 'Risk Insight' },
  { mainKey: 'user_guide', sectionKey: 'risk_insight', pageKey: 'hosts', label: 'Hosts' },
  { mainKey: 'user_guide', sectionKey: 'risk_insight', pageKey: 'patches', label: 'Patches' },
  { mainKey: 'user_guide', sectionKey: 'risk_insight', pageKey: 'vulnerabilities', label: 'Vulnerabilities' },
  { mainKey: 'user_guide', sectionKey: 'risk_insight', pageKey: 'instances', label: 'Vulnerability Instances' },
  { mainKey: 'user_guide', sectionKey: 'risk_insight', pageKey: 'users', label: 'Users' },
  // user_guide / remediation
  { mainKey: 'user_guide', sectionKey: 'remediation', pageKey: 'remediation_section_index', label: 'Remediation Workflow Manager' },
  { mainKey: 'user_guide', sectionKey: 'remediation', pageKey: 'remediation_plans', label: 'Plans' },
  { mainKey: 'user_guide', sectionKey: 'remediation', pageKey: 'remediation_settings', label: 'Settings'},
  { mainKey: 'user_guide', sectionKey: 'remediation', pageKey: 'remediation_ticketing', label: 'Integrations' },
  { mainKey: 'user_guide', sectionKey: 'remediation', pageKey: 'remediation_workflow', label: 'Workflow' },
  { mainKey: 'user_guide', sectionKey: 'remediation', pageKey: 'remediation_exporting', label: 'Exporting' },
  { mainKey: 'user_guide', sectionKey: 'remediation', pageKey: 'accepted_risk_plans', label: 'Accepted Risk Plans' },
  { mainKey: 'user_guide', sectionKey: 'remediation', pageKey: 'accepted_risk_workflow', label: 'Accepted Risk Workflow' },
  // user_guide / explore
  { mainKey: 'user_guide', sectionKey: 'explore', pageKey: 'explore_section_index', label: 'Explore' },
  { mainKey: 'user_guide', sectionKey: 'explore', pageKey: 'explore_model', label: 'Model' },
  { mainKey: 'user_guide', sectionKey: 'explore', pageKey: 'paths', label: 'Paths' },
  // user_guide / activity
  { mainKey: 'user_guide', sectionKey: 'activity', pageKey: 'activity_section_index', label: 'Activity' },
  { mainKey: 'user_guide', sectionKey: 'activity', pageKey: 'tasks', label: 'Tasks' },
  { mainKey: 'user_guide', sectionKey: 'activity', pageKey: 'configuration_alerts', label: 'Configuration Alerts' },
  { mainKey: 'user_guide', sectionKey: 'activity', pageKey: 'scan_logs', label: 'Scan Logs' },
  { mainKey: 'user_guide', sectionKey: 'activity', pageKey: 'notification_settings', label: 'Notification Settings' },
  // user_guide / scanning
  { mainKey: 'user_guide', sectionKey: 'scanning', pageKey: 'scanning_section_index', label: 'Scanning' },
  { mainKey: 'user_guide', sectionKey: 'scanning', pageKey: 'scanning_dashboard', label: 'Status' },
  { mainKey: 'user_guide', sectionKey: 'scanning', pageKey: 'agents', label: 'Agents' },
  { mainKey: 'user_guide', sectionKey: 'scanning', pageKey: 'user_managed', label: 'User Managed' },
  { mainKey: 'user_guide', sectionKey: 'scanning', pageKey: 'agentless_settings', label: 'Credentialed Scanning Settings' },
  { mainKey: 'user_guide', sectionKey: 'scanning', pageKey: 'credentials', label: 'Credentials' },
  { mainKey: 'user_guide', sectionKey: 'scanning', pageKey: 'scan_groups', label: 'Scan Groups' },
  { mainKey: 'user_guide', sectionKey: 'scanning', pageKey: 'scanning_general_settings', label: 'General Settings' },
  { mainKey: 'user_guide', sectionKey: 'scanning', pageKey: 'cloud_scanning', label: 'Cloud Scanning' },
  { mainKey: 'user_guide', sectionKey: 'scanning', pageKey: 'connectivity_settings', label: 'Network Connectivity' },
  { mainKey: 'user_guide', sectionKey: 'scanning', pageKey: 'subordinates', label: 'Subordinates' },
  { mainKey: 'user_guide', sectionKey: 'scanning', pageKey: 'vulnerability_scanners', label: 'Vulnerability Sources' },
  // user_guide / setup
  { mainKey: 'user_guide', sectionKey: 'setup', pageKey: 'setup_section_index', label: 'Setup' },
  { mainKey: 'user_guide', sectionKey: 'setup', pageKey: 'sensitive_assets_policies', label: 'Sensitive Assets: Polices' },
  { mainKey: 'user_guide', sectionKey: 'setup', pageKey: 'sensitive_assets_manual', label: 'Sensitive Assets: Manual' },
  { mainKey: 'user_guide', sectionKey: 'setup', pageKey: 'admin', label: 'Admin Settings' },
  { mainKey: 'user_guide', sectionKey: 'setup', pageKey: 'smtp', label: 'SMTP Settings' },
  { mainKey: 'user_guide', sectionKey: 'setup', pageKey: 'certificates', label: 'Certificates' },
  { mainKey: 'user_guide', sectionKey: 'setup', pageKey: 'outbound_proxy', label: 'Outbound Proxy' },
  { mainKey: 'user_guide', sectionKey: 'setup', pageKey: 'providers', label: 'Authentication Providers' },
  { mainKey: 'user_guide', sectionKey: 'setup', pageKey: 'users_setup', label: 'Users' },
  { mainKey: 'user_guide', sectionKey: 'setup', pageKey: 'tags', label: 'Tags' },

  { mainKey: 'integrations_guide', sectionKey: null, pageKey: 'integrations_guide_main_index', label: 'Integrations Guide' },
  // integrations_guide / vulnerability_scanners
  { mainKey: 'integrations_guide', sectionKey: 'vulnerability_scanners', pageKey: 'vulnerability_scanners_section_index', label: 'Vulnerability Sources' },
  { mainKey: 'integrations_guide', sectionKey: 'vulnerability_scanners', pageKey: 'crowdstrike', label: 'CrowdStrike Spotlight' },
  { mainKey: 'integrations_guide', sectionKey: 'vulnerability_scanners', pageKey: 'sentinelone', label: 'SentinelOne' },
  { mainKey: 'integrations_guide', sectionKey: 'vulnerability_scanners', pageKey: 'cbc', label: 'Carbon Black Cloud' },
  { mainKey: 'integrations_guide', sectionKey: 'vulnerability_scanners', pageKey: 'msde', label: 'Microsoft Defender for Endpoint' },
  { mainKey: 'integrations_guide', sectionKey: 'vulnerability_scanners', pageKey: 'wazuh', label: 'Wazuh' },
  { mainKey: 'integrations_guide', sectionKey: 'vulnerability_scanners', pageKey: 'lansweeper', label: 'Lansweeper Cloud' },
  { mainKey: 'integrations_guide', sectionKey: 'vulnerability_scanners', pageKey: 'nessus', label: 'Nessus API' },
  { mainKey: 'integrations_guide', sectionKey: 'vulnerability_scanners', pageKey: 'tenablesc', label: 'Tenable.io API' },
  { mainKey: 'integrations_guide', sectionKey: 'vulnerability_scanners', pageKey: 'tenableio', label: 'Security Center/Tenable.sc API' },
  { mainKey: 'integrations_guide', sectionKey: 'vulnerability_scanners', pageKey: 'rapid7', label: 'Rapid7 InsightVM API' },
  { mainKey: 'integrations_guide', sectionKey: 'vulnerability_scanners', pageKey: 'qualys', label: 'Qualys API' },
  { mainKey: 'integrations_guide', sectionKey: 'vulnerability_scanners', pageKey: 'nozomi', label: 'Nozomi Guardian' },
  { mainKey: 'integrations_guide', sectionKey: 'vulnerability_scanners', pageKey: 'eclypsium', label: 'Eclypsium' },
  { mainKey: 'integrations_guide', sectionKey: 'vulnerability_scanners', pageKey: 'aws_inspector', label: 'AWS Inspector' },
  // integrations_guide / remediation_integrations
  { mainKey: 'integrations_guide', sectionKey: 'remediation_integrations', pageKey: 'remediation_integrations_section_index', label: 'Remediation' },
  { mainKey: 'integrations_guide', sectionKey: 'remediation_integrations', pageKey: 'jira', label: 'Jira Software' },
  { mainKey: 'integrations_guide', sectionKey: 'remediation_integrations', pageKey: 'tanium', label: 'Tanium (BETA)' },
  // integrations_guide / providers
  { mainKey: 'integrations_guide', sectionKey: 'providers', pageKey: 'providers_section_index', label: 'Authentication Providers' },
  { mainKey: 'integrations_guide', sectionKey: 'providers', pageKey: 'ldap_auth', label: 'LDAP (Active Directory)' },
  { mainKey: 'integrations_guide', sectionKey: 'providers', pageKey: 'saml_azure', label: 'SAML (Microsoft Entra ID)' },
  { mainKey: 'integrations_guide', sectionKey: 'providers', pageKey: 'saml_azure_autoprov', label: 'SAML Auto-Provisioning (Microsoft Entra ID)' },
  { mainKey: 'integrations_guide', sectionKey: 'providers', pageKey: 'saml_google', label: 'SAML (Google)' },
  { mainKey: 'integrations_guide', sectionKey: 'providers', pageKey: 'saml_google_autoprov', label: 'SAML Auto-Provisioning (Google)' },
  { mainKey: 'integrations_guide', sectionKey: 'providers', pageKey: 'saml_okta', label: 'SAML (Okta)' },
  { mainKey: 'integrations_guide', sectionKey: 'providers', pageKey: 'saml_okta_autoprov', label: 'SAML Auto-Provisioning (Okta)' },
  // integrations_guide / pam
  { mainKey: 'integrations_guide', sectionKey: 'pam', pageKey: 'pam_section_index', label: 'PAM' },
  { mainKey: 'integrations_guide', sectionKey: 'pam', pageKey: 'cyberark', label: 'CyberArk' },
  { mainKey: 'integrations_guide', sectionKey: 'pam', pageKey: 'delinea', label: 'Delinea (Thycotic)' },
  { mainKey: 'integrations_guide', sectionKey: 'pam', pageKey: 'laps', label: 'Microsoft LAPS' },

  { mainKey: 'security_guide', sectionKey: null, pageKey: 'security_guide_main_index', label: 'Security Guide' },
  // security_guide / firewall_config
  { mainKey: 'security_guide', sectionKey: 'firewall_config', pageKey: 'firewall_config_section_index', label: 'Firewall Configuration' },
  { mainKey: 'security_guide', sectionKey: 'firewall_config', pageKey: 'vm_network_requirements', label: 'Base Network Requirements' },
  { mainKey: 'security_guide', sectionKey: 'firewall_config', pageKey: 'agent_network_requirements', label: 'Agent Network Requirements' },
  { mainKey: 'security_guide', sectionKey: 'firewall_config', pageKey: 'agentless_network_requirements', label: 'Credentialed Scanning Network Requirements' },
  { mainKey: 'security_guide', sectionKey: 'firewall_config', pageKey: 'api_network_requirements', label: 'API Network Requirements' },
  // security_guide / how_scans_work
  { mainKey: 'security_guide', sectionKey: 'how_scans_work', pageKey: 'how_scans_work_section_index', label: 'How DeepSurface Scans Work' },
  { mainKey: 'security_guide', sectionKey: 'how_scans_work', pageKey: 'domain_scans', label: 'Domain (LDAP) Scanning' },
  { mainKey: 'security_guide', sectionKey: 'how_scans_work', pageKey: 'host_scans', label: 'Host Scanning Routine' },
  { mainKey: 'security_guide', sectionKey: 'how_scans_work', pageKey: 'access_reasons', label: 'Reasons for the Administrative Access Requirement' },
  { mainKey: 'security_guide', sectionKey: 'how_scans_work', pageKey: 'endpoint_protection', label: 'Endpoint Protection Considerations' },
  // security_guide / other_security_items
  { mainKey: 'security_guide', sectionKey: 'other_security_items', pageKey: 'other_security_items_section_index', label: 'Other Items' },
  { mainKey: 'security_guide', sectionKey: 'other_security_items', pageKey: 'data_retention', label: 'Scope of Data Storage and Retention' },
  { mainKey: 'security_guide', sectionKey: 'other_security_items', pageKey: 'ips', label: 'IPS/IDS Considerations' },
  { mainKey: 'security_guide', sectionKey: 'other_security_items', pageKey: 'logging', label: 'Logging' },
  { mainKey: 'security_guide', sectionKey: 'other_security_items', pageKey: 'reset_dsadmin_pw', label: 'Resetting the DSADMIN password' },

  { mainKey: 'product_information', sectionKey: null, pageKey: 'product_information', label: 'Product Information' },
  // product_information
  { mainKey: 'product_information', sectionKey: null, pageKey: 'changelogs', label: 'Changelogs' },
  { mainKey: 'product_information', sectionKey: null, pageKey: 'licenses', label: 'Open source Licenses' },
  { mainKey: 'product_information', sectionKey: null, pageKey: 'eula', label: 'End User License Agreement (EULA)' },
];

// ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ //
// IMPORTANT ------------------------------------------------------------- //

export const getLabelFor = ( key, type ) => {
  if ( isNotEmpty( key ) && isNotEmpty( type ) ) {
    if ( type === 'main' ) {
      const entry = mainOrderAndLabels.find( m => m.mainKey === key );
      if ( isNotEmpty( entry ) ) {
        return entry.label;
      }
      return capitalize( key );
    }
    if ( type === 'section' ) {
      const entry = sectionOrderAndLabels.find( s => s.sectionKey === key );
      if ( isNotEmpty( entry ) ) {
        return entry.label;
      }
      return capitalize( key );
    }
    if ( type === 'page' ) {
      const entry = pageOrderAndLabels.find( p => p.pageKey === key );
      if ( isNotEmpty( entry ) ) {
        return entry.label;
      }
      return capitalize( key );
    }
  } else if ( isNotEmpty( key ) ) {
    return capitalize( key );
  }
  return 'N/A';
};

// returns an array of sections with labels for a given main key
export const getOrderedSectionsForMain = mainKey => {
  let orderedSections = [];

  if ( isNotEmpty( mainKey ) ) {
    orderedSections = sectionOrderAndLabels.filter( s => s.mainKey === mainKey );
  }

  return orderedSections;
};

// returns all the page objects in order as an array for a given main and section key
export const getOrderedPagesForSection = ( mainKey, sectionKey ) => {
  let orderedPages = [];

  if ( isNotEmpty( mainKey ) && isNotEmpty( sectionKey ) ) {
    orderedPages = pageOrderAndLabels.filter( p => p.mainKey === mainKey && p.sectionKey === sectionKey );
  }
  return orderedPages;
};

// get the corresponding moduleKey for a piece of content that needs to be shown
export const getModuleKey = ( main, section, page, item ) => {

  // special case for eula page
  if ( page === 'eula' ) {
    return `./product_information/${page}.md`;
  }
  // special case for the document index
  if ( isEmpty( main ) && isEmpty( section ) && isNotEmpty( page ) && page === 'documentation_index' ) {
    return './documentation_index.md';
  }
  // special case for a main_index page
  if ( isNotEmpty( main ) && isNotEmpty( page ) && isEmpty( section ) && page.includes( '_main_index' ) ) {
    return `./${main}/${page}.md`;
  }
  // page key
  if ( isNotEmpty( main ) && isNotEmpty( section ) && isNotEmpty( page ) && isEmpty( item ) ) {
    return `./${main}/${section}/${page}.md`;
  }
  // item key
  if ( isNotEmpty( item ) ) {
    return `./contextual_help/${item}.md`;
  }
};

// the app needs to keep track of the main, section, and page we are on.
// this function finds all 3 of those things by passing in a key that is for a main, section, or page.
export const getMainSectionPageKeys = ( type, key ) => {
  let _mainKey, _sectionKey, _pageKey;

  // PAGE
  // easiest way, just return all the keys for the page object
  if ( type === 'page' ) {
    _pageKey = key;

    const page = pageOrderAndLabels.find( p => p.pageKey === key );
    _mainKey = page?.mainKey;
    _sectionKey = page?.sectionKey;
  // SECTION
  // return the section index page
  } else if ( type === 'section' ) {
    _sectionKey = key;

    const page = pageOrderAndLabels.find( p => p.pageKey === `${key}_section_index` );
    _mainKey = page?.mainKey;
    _pageKey = page?.pageKey;
  // MAIN
  } else if ( type === 'main' ) {
    _mainKey = key;
    _sectionKey = null;
    _pageKey = `${key}_main_index`;
  }

  return ( { _mainKey, _sectionKey, _pageKey } );
};

// main function for returning a piece of md content
export const fetchHelpDocumentation = async ( help_page ) => {

  if ( isNotEmpty( help_page ) ) {
    // there are only pages, so therefore, if a fetch comes in asking for just main or just section, it needs
    // to instead return the the first page of the first section of that main.

    const page = pageOrderAndLabels.find( p => p.pageKey === help_page );

    if ( isNotEmpty( page ) ) {
      const { mainKey, sectionKey, pageKey } = pageOrderAndLabels.find( p => p.pageKey === help_page );

      const moduleKey = getModuleKey( mainKey, sectionKey, pageKey );

      if ( isNotEmpty( moduleKey ) && isNotEmpty( keyedModules[moduleKey] ) ) {
        const file = await fetch( keyedModules[moduleKey].default );
        const content = await file.text();
        return ( { content } );
      }
      return ( { content: '' } );
    }
    return( { content: 'This help page does not exist' } );
  }
  return ( { content: '' } );
};

// pass in a pageKey, get all the contextual help items for that page, only relevent to the app
export const getContextualHelpItemsForPage = async ( sectionKey, pageKey, shouldLoadGlobal=false ) => {
  const itemsPromises = [];
  const formattedItems = {};
  if (
    isNotEmpty( sectionKey )
    && isNotEmpty( pageKey )
  ) {
    if ( sectionKey !== 'help_documentation' && isNotEmpty( sectionKey ) && isNotEmpty( pageKey ) ) {

      const itemKeys = contextualHelpMap[sectionKey][pageKey];

      if ( isNotEmpty( itemKeys ) ) {

        itemKeys.map( itemKey => {
          const moduleKey = getModuleKey( null, null, null, itemKey );
          itemsPromises.push( getMarkdownFileContent( moduleKey ) );
        } );

        const items = await Promise.all( itemsPromises );

        items.map( ( item, index ) => {
          const itemKey = itemKeys[index];
          formattedItems[itemKey] = item;
        } );
      }
    }
  }
  if ( shouldLoadGlobal ) {
    // eslint-disable-next-line camelcase
    formattedItems.risk_score = await getMarkdownFileContent( 'risk_score' );
  }

  // adds all global help to every page (ie: risk score)
  return formattedItems;
};

export const getLicensesContent = async () => {
  const licenseKeys = [];
  const licensePromises = [];
  const formattedContent = {};

  if ( isNotEmpty( keyedModules ) ) {
    Object.keys( keyedModules ).map( ( key ) => {
      if ( key.includes( '/open_source_licenses/' ) ) {

        const keyParts = key.split( '/' );
        let filename = keyParts[ keyParts.length - 1 ];
        filename = filename.replace( '.md', '' );

        licenseKeys.push( filename );
        licensePromises.push( getMarkdownFileContent( key ) );
      }
    } );

    const licenses = await Promise.all( licensePromises );

    licenses.map( ( license, index ) => {
      const licenseKey = licenseKeys[index];
      // need to shoehorn in some more content
      const licenseWithKey = `## ${licenseKey}\n\n<pre>${license.content}</pre>`;
      formattedContent[licenseKey] = { content: licenseWithKey };
    } );
  }
  return formattedContent;
};
export const getChangeLogContent = async () => {
  const changelogKeys = [];
  const changelogPromises = [];
  const formattedContent = {};

  if ( isNotEmpty( keyedModules ) ) {
    Object.keys( keyedModules ).map( ( key ) => {
      if ( key.includes( '/change_logs/' ) ) {

        const keyParts = key.split( '/' );
        let filename = keyParts[ keyParts.length - 1 ];
        filename = filename.replace( '.md', '' );

        changelogKeys.push( filename );
        changelogPromises.push( getMarkdownFileContent( key ) );
      }
    } );

    const changelogs = await Promise.all( changelogPromises );

    changelogs.map( ( log, index ) => {
      const logKey = changelogKeys[index];
      formattedContent[logKey] = log;
    } );
  }

  return formattedContent;
};

// takes a module key, returns the content of the associated file
export const getMarkdownFileContent = async ( moduleKey ) => {
  if ( isNotEmpty( moduleKey ) && isNotEmpty( keyedModules[moduleKey] ) ) {
    const file = await fetch( keyedModules[moduleKey].default );
    const content = await file.text();
    return ( { content } );
  }
  return ( { content: '' } );
};

// the corresponding contextual help items for any given page, app only
export const contextualHelpMap = {
  reporting: {
    reporting_dashboard: [
      'total_instances',
      'exploit_status',

      // report creator
      'name_report_creator',
      'owner',
      'format',
      'schedule',
      'expires',
      'email_recipients',
      'include_all_results',

      // categories
      'deprioritized',
      'dos_only',
      'not_exploitable',
      'overridden',
      'for_review',
      'unrecognized',
      'missing_host',
      'unsupported',
      'cannot_model',
      'insufficient_information',
      'missing_capability',
      'prioritized',
      'unreachable',
      'carries_risk',

      // specific fields in widget editor
      'tag_dashboard',
    ],
  },
  risk_insight: {
    hosts: [
      // unique to hosts
      'sensitive_assets',

      // shared by all reports
      'exploit_status',
      'keywords',
      'tag',
      'item_count',
      'include_accepted_risk_items',
      'specific_host',
      'specific_patch',
      'specific_vulnerability',
      'specific_scanner_signature',
      'category',
      'host_wildcard',
      'patch_wildcard',
      'vulnerability_wildcard',
      'vulnerability_scanners',
      'host_os_name',
      'host_os_type',
      'host_os_architecture',
      'host_os_vendor',

      // instance categories
      'deprioritized',
      'for_review',
      'carries_risk',

      // report creator
      'name_report_creator',
      'owner',
      'format',
      'schedule',
      'expires',
      'email_recipients',
      'include_all_results',
    ],
    patches: [
      // unique to patches
      'risk_type',
      'published_date',
      'superseded',

      // shared by all reports
      'exploit_status',
      'keywords',
      'tag',
      'item_count',
      'include_accepted_risk_items',
      'specific_host',
      'specific_patch',
      'specific_vulnerability',
      'specific_scanner_signature',
      'category',
      'host_wildcard',
      'patch_wildcard',
      'vulnerability_wildcard',
      'vulnerability_scanners',
      'host_os_name',
      'host_os_type',
      'host_os_architecture',
      'host_os_vendor',

      // instance categories
      'deprioritized',
      'for_review',
      'carries_risk',

      // report creator
      'name_report_creator',
      'owner',
      'format',
      'schedule',
      'expires',
      'email_recipients',
      'include_all_results',
    ],
    vulnerabilities: [
      // unique to vulns
      'published_date',
      'patchable',

      // shared by all reports
      'exploit_status',
      'keywords',
      'tag',
      'item_count',
      'specific_host',
      'specific_patch',
      'specific_vulnerability',
      'specific_scanner_signature',
      'category',
      'host_wildcard',
      'patch_wildcard',
      'vulnerability_wildcard',
      'vulnerability_scanners',
      'include_accepted_risk_items',
      'host_os_name',
      'host_os_type',
      'host_os_architecture',
      'host_os_vendor',

      // instance categories
      'deprioritized',
      'for_review',
      'carries_risk',

      // report creator
      'name_report_creator',
      'owner',
      'format',
      'schedule',
      'expires',
      'email_recipients',
      'include_all_results',
    ],
    instances: [
      // filters
      'specific_host',
      'specific_patch',
      'specific_vulnerability',
      'specific_scanner_signature',
      'category',
      'tag',
      'host_wildcard',
      'patch_wildcard',
      'vulnerability_wildcard',
      'exploit_status',
      'vulnerability_scanners',
      'include_accepted_risk_items',
      'host_os_name',
      'host_os_type',
      'host_os_architecture',
      'host_os_vendor',

      // report creator
      'name_report_creator',
      'owner',
      'format',
      'schedule',
      'expires',
      'email_recipients',
      'include_all_results',

      // categories
      'deprioritized',
      'dos_only',
      'not_exploitable',
      'overridden',

      'for_review',
      'unrecognized',
      'missing_host',
      'unsupported',
      'cannot_model',
      'insufficient_information',
      'missing_capability',

      'prioritized',
      'unreachable',
      'carries_risk',
    ],
    users: [
      'exploit_status',

      // report creator
      'name_report_creator',
      'owner',
      'format',
      'schedule',
      'expires',
      'email_recipients',
      'include_all_results',
    ],
  },
  remediation: {
    remediation_settings: [
      'automatic_task_closing',
    ],
    remediation_ticketing: [
      'tool',
      'label_remediation',

      'jira_domain',
      'jira_username',
      'jira_api_token',
      'jira_project',

      'destination_email_address',
      'subject_prefix',

      'tanium_server',
      'tanium_token',
    ],
  },
  explore: {
    explore_model: [
      'node_label',
      'node_scope',
      'node_type',
      'node_description',
      'node_impact',
      'scope_label',
      'parent_scope',
      'risk_level',
      'scope_type',
      'vulnerability_fields',
      'vulnerability_description',
      'vulnerability_references',
      'remediation_effort',
      'likelihood_metrics',
      'user_interaction',
      'disruption',
      'exploit_development',
      'identification',
      'certainty',
      'completeness',
    ],
    paths: [
      'exploit_status',

      // instance categories
      'deprioritized',
      'for_review',
      'carries_risk',
    ],
  },
  activity: {
    tasks: [
      'feed',
      'agentless_scan',
      'process_queue',
      'import',
      'analysis',
      'upgrade',
    ],
    configuration_alerts: [
      'configuration_alerts',
    ],
  },
  scanning: {
    scanning_dashboard: [
      'scanning_mode',
      'host_wildcard',
      'agent_version',
      'enter_ip_manually',
      'ip_address',
      'scan_groups',
      'tag',
    ],
    agentless_settings: [
      'scan_group_default_settings',
      'avoid_rescan_period',
      'maximum_duration',
      'scan_workers',
      'scan_domains',
      'mssql_grant_privileges',
      'winrm_allow_http',

      'scan_max_workers',
      'scan_hard_stop_time',
    ],
    credentials: [
      'pam_type',

      'username',
      'password',
      'ports',
      'domain',

      'laps_username',
      'laps_password',
      'laps_local_user',

      'delinea_api_username',
      'delinea_api_password',
      'url',

      'target_domain',
      'target_username',
      'secret_name',
      'tofu',

      'cyberark_url',
      'cyberark_safe_name',
      'cyberark_target_username',
      'cyberark_host_username',
      'cyberark_target_domain',
      'cyberark_api_username',
      'cyberark_api_password',

      'pam_client_cert',
      'pam_client_key',
      'cyberark_require_ip_match',
    ],
    scan_groups: [
      'scan_groups',
      'label',
      'ip_address',
      'enter_ip_manually',
      'subordinate_scanner',
      'included_ranges',
      'excluded_ranges',
      'schedule',
      'credentials',
      'scan_default_overrides',
      'winrm_allow_http',
      'scan_domains',
      'mssql_grant_privileges',
      'scan_workers',
      'maximum_duration',
      'avoid_rescan_period',
    ],
    scanning_general_settings: [
      'auto_add_windows_domains',
      'windows_domains',
      'third_party_ignore_age',
    ],
    cloud_scanning: [
      'use_outbound_proxy',
    ],
    connectivity_settings: [
      'connectivity_max_workers',
      'connectivity_port_delay',
      'connectivity_port_timeout',
      'connectivity_ports_per_client',
    ],
    vulnerability_scanners: [
      'vulnerability_scanners',
      'qualys_api',
      'nessus_pro',
      'tenable_io',
      'tenable_sc',
      'rapid7_insight_vm',
      'microsoft_defender_for_endpoint',
      'use_outbound_proxy',
      'trust_on_first_use',
      'include_wazuh_host',
    ],
  },
  setup: {
    sensitive_assets_manual: [
      'sensitive_assets',
      'type',
    ],
    admin: [
      'analysis_max_workers',
      'analysis_path_tail_ratio',
      'analysis_target_paths',
      'risk_target',
      'admin_session_active_timeout',
      'admin_session_inactive_timeout',
      'send_telemetry',
      'update_feed_time',
      'speculative_hijacking',
    ],
    smtp: [
      'smtp_hostname',
      'smtp_port',
      'smtp_sender',
      'smtp_tofu',
      'smtp_enable_authentication',
      'test_smtp_settings',
    ],
    certificates: [
      'identity',
      'purpose',
      'certificate',
    ],
    outbound_proxy: [
      'enable_outbound_proxy',
      'protocol',
      'proxy_server',
      'port',
      'proxy_username',
      'proxy_password',
    ],
    providers: [
      'autoprovision_authentication_provider',
      'provider_type',
      'name',
      'servers',
      'encryption_protocol',
      'acs_url',
      'metadata_xml',
      'port_number',
      'windows_domain',
      'trust_on_first_use',
    ],
    users: [
      'api_credentials',
    ],
    tags: [
      'filter_options',
      'name_tag',
      'owner_tag',
      'specific_hosts',
      'excluded_specific_hosts',
      'ip_ranges',
      'excluded_ip_ranges',
      'host_wildcards',
      'excluded_host_wildcards',
    ],
  },
};