/** **************************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ****************************************************************************/

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

const renderEl = document.getElementById( 'react_container' );

// NOTE: needed to remove React.StrictMode wrapper so that the draggable core
// (used by help and onboarding wizard) could function
ReactDOM.render(
  <App />,
  renderEl,
);
