/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import { svgMap, defaultSVG } from './data';
import { isNotEmpty } from '../Utilities';

import './style.scss';

const InlineSVG = ( { type, version='dark', size='medium', elementClass='' } ) => {

  const [ svgContent, setSvgContent ] = React.useState( null );

  React.useEffect( () => {
    if ( isNotEmpty( type ) ) {
      const _svg = svgMap[type] ? svgMap[type] : defaultSVG;
      setSvgContent( _svg );
    }
  }, [ type ] );

  const sizes = {
    xsmall: '12',
    small: '16',
    medium: '24',
    large:  '32',
    xlarge: '64',
    logoBug: {
      width: '44',
      height: '53',
    },
    logoText: {
      width: '114',
      height: '18',
    },
    logoAlt: {
      width: '500',
      height: '168',
    },
    logoTextDemo: {
      width: '207',
      height: '47',
    },
    longArrowRight: {
      width: '38',
      height: '16',
    },
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( svgContent ) &&
        <svg
          className={`inlineSVG ${version} ${elementClass}`}
          width={sizes[size].width ? sizes[size].width : sizes[size]}
          height={sizes[size].height ? sizes[size].height : sizes[size]}
          // eslint-disable-next-line max-len
          viewBox={ `0 0 ${sizes[size].width ? sizes[size].width : sizes[size]} ${sizes[size].height ? sizes[size].height : sizes[size]}` }
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          { svgContent }
        </svg>
      }
    </React.Fragment>

  );
};

export default InlineSVG;