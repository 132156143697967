/** **************************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ****************************************************************************/

import React from 'react';
import './style.scss';
import { globalColors } from '../Utilities';

const Loading = ( { text='Loading...', minimalVariant=false } ) => {

  let loadingInterval;

  const [ rectVariables, setRectVariables ] = React.useState( {
    0: { fill: globalColors['grey--background'], height: 0 },
    1: { fill: globalColors['grey--background'], height: 2 },
    2: { fill: globalColors['grey--background'], height: 6 },
    3: { fill: globalColors['grey--background'], height: 5 },
    4: { fill: globalColors['grey--background'], height: 7 },
    5: { fill: globalColors['grey--background'], height: 1 },
    6: { fill: globalColors['grey--background'], height: 8 },
    7: { fill: globalColors['grey--background'], height: 4 },
    8: { fill: globalColors['grey--background'], height: 10 },
    9: { fill: globalColors['grey--background'], height: 3 },
  } );

  React.useEffect( () => {
    if ( minimalVariant ) {
      loadingInterval = window.setInterval( () => {

        const vars = { ...rectVariables };

        Object.keys( vars ).map( ( index ) => {

          const rand = Math.ceil( Math.random() * 10 );

          vars[index].height = rand;
        } );
        setRectVariables( vars );
      }, 500 );
    }
    return () => clearInterval( loadingInterval );
  }, [ minimalVariant ] );

  return (
    <React.Fragment>
      {
        minimalVariant
          ? <div className="minimalLoadingAnimationWrapper">
            <svg
              viewBox="0 0 100 15"
              xmlns="http://www.w3.org/1500/svg"
              className="loadingAnimation"
              preserveAspectRatio="none"
            >
              {
                Object.entries( rectVariables ).map( ( [ rectIndex, rectData ] ) => {
                  return <rect
                    key={ rectIndex }
                    x={rectIndex * 10}
                    y={ ( 15 - rectData.height ) / 2 }
                    width={ 8 }
                    height={ rectData.height }
                    fill={ rectData.fill }
                    className="loadingRect"
                  />;
                } )
              }
            </svg>
            {/* <span className="loadingText">Loading...</span> */}
          </div>

          : <div className="loadingContainer">
            <div className="loadingWrapper">
              <React.Fragment>
                <div className="loadingBlocksContainer">
                  <div className="loadingBlock"></div>
                  <div className="loadingBlock two"></div>
                  <div className="loadingBlock three"></div>
                  <div className="loadingBlock four"></div>
                </div>
                <div className="messageContainer">{ text }</div>
              </React.Fragment>
            </div>
          </div>
      }
    </React.Fragment>
  );
};

export default Loading;
